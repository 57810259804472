import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, TextField } from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import ShopSelector from '../components/ShopSelector';
import { POS_TYPES, addPosConfig, deletePosConfig, loadPosConfig, updatePosConfig } from '../APIHelper';
import { setPosConfig } from '../redux/appSlice';
import DefaultFrame from '../components/DefaultFrame';

export class ExpertOrderConfigPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            apiKey: ""
        }
        this.initialPosconfig = {};

        // InputLabelProps={{
        //     shrink: this.state.apiKey !== '', // Das Label wird oben bleiben, wenn inputValue nicht leer ist
        //   }} 
    }
    save() {
        if (this.initialPosconfig.API_KEY == null && this.state.apiKey.length > 0 || this.initialPosconfig.API_KEY != null && this.initialPosconfig.API_KEY !== this.state.apiKey) {
            console.log("Changes have been made -> Change");
            let newPosConfigBody = { "API_KEY": this.state.apiKey }
            if (this.props.posConfig.id != null) {

                updatePosConfig(this.props.currentShopId, this.props.posConfig.id, POS_TYPES.EXPERTORDER, newPosConfigBody)
                    .then(() => {
                        this.initialPosconfig = { ...newPosConfigBody }
                    }).catch(e => {
                        console.log("Error while update: ", e)
                        alert("Beim Aktualisieren der Konfiguration ist ein Fehler aufgetreten.");
                    })
            } else {
                addPosConfig(this.props.currentShopId, POS_TYPES.EXPERTORDER, newPosConfigBody)
                    .then(() => {
                        loadPosConfig(this.props.currentShopId, POS_TYPES.EXPERTORDER)
                            .then(result => {
                                this.props.setPosConfig(result);
                                if (result.configFields != null) {
                                    this.initialPosconfig = { ...result.configFields };
                                    this.setState({ apiKey: result?.configFields?.API_KEY });
                                }
                            }).catch(e => {
                                alert("Beim Hinzufügen der Konfiguration ist ein Fehler aufgetreten (001).");
                                console.log("Error while adding posConfig: ", e);
                            })
                    }).catch(e => {
                        alert("Beim Hinzufügen der Konfiguration ist ein Fehler aufgetreten (002).");
                    })
            }
        } else {
            alert("Everything already up to date.");
        }
    }
    delete() {
        let posConfBackup = { ...this.props.posConfig };
        let posInitialConfigBackup = { ...this.initialPosconfig }
        let apiKey = this.state.apiKey;
        this.props.setPosConfig({});
        this.initialPosconfig = {};

        this.setState({ apiKey: "" })
        deletePosConfig(this.props.currentShopId, this.props.posConfig.id)
            .catch(e => {
                alert("Fehler beim Löschen der Anbindung.");
                console.log("ExpertOrder - Fehler beim Löschen der Anbindung: ", e);
                this.props.setPosConfig({ ...posConfBackup });
                this.initialPosconfig = { ...posInitialConfigBackup };
                this.setState({ apiKey: apiKey })
            })
    }
    onSelectedShopChange(shopId) {
        if (shopId == null) {
            return;
        }
        loadPosConfig(shopId, POS_TYPES.EXPERTORDER)
            .then(result => {
                console.log("PosConfig: ", result);
                this.props.setPosConfig(result);
                if (result.configFields != null) {
                    this.initialPosconfig = { ...result.configFields };
                    this.setState({ apiKey: result?.configFields?.API_KEY });
                }
            }).catch(e => {
                console.log("Error while loading posConfig in ExpertOrderConfigPage: ", e);
            })
    }
    render() {
        return (
            <div >
                <div style={{ flex: 1, display: "flex", flexDirection: "column", padding: 20, overflowY: "scroll" }}>
                    <div style={{ flex: 1, }}>
                        {/* <ShopSelector posId={POS_TYPES.EXPERTORDER} returnInitialPosConfig={(posConfig) => { this.initialPosconfig = posConfig; this.setState({ apiKey: posConfig.API_KEY != null ? posConfig.API_KEY : "" }) }} /> */}
                        <ShopSelector onChange={(shopId) => { this.onSelectedShopChange(shopId) }} />
                        <p>Bitte ExpertOrder um einen einen API-Key und trage den Wert im folgenden ein:</p><br /><br />
                        <TextField onChange={(evt) => { this.setState({ apiKey: evt.target.value }) }} style={{ width: "100%", marginTop: 10 }} label='API-Key' value={this.state.apiKey} />
                    </div>
                    <div>

                        <Button onClick={() => { this.save() }} style={{ marginTop: 10, width: "100%" }} variant='contained' startIcon={<Save />}>Speichern</Button>
                        <Button onClick={() => { this.delete() }} style={{ marginTop: 10, width: "100%" }} variant='contained' color='error' startIcon={<Delete />}>Löschen</Button>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        shopIdList: state.app.shopIdList,
        posConfig: state.app.posConfig,
        currentShopId: state.app.currentShopId,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setPosConfig: (posConf) => {
            dispatch(setPosConfig(posConf));
        }
    }
}
// export default connect(mapStateToProps, mapDispatchToProps)(ExpertOrderConfigPage)
class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="ExpertOrder - Konfiguration" noPermissionCheck>
                <ExpertOrderConfigPage {...this.props} />
            </DefaultFrame>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(defaultWrapper);
