import { APIData } from "./constants/APIAdresses";

// export class APIHelper {

const LOGIN_TYPES = { FACEBOOK: 'facebook', GOOGLE: 'google', APPLE: 'apple', INTERNAL: "internal" };
export const POS_TYPES = { LIEFERSOFT: 1, WINORDER: 2, EXPERTORDER: 3, READY2ORDER: 4 }
// resolves with {role: }
export function destroySession() {
    return new Promise((resolve, reject) => {
        let uri = APIData.USER + "/auth/session/destroy";
        fetch(uri, { credentials: "include" })
            .then(response => {
                if (response.ok) {
                    resolve();
                } else {
                    reject();
                }
            }).catch(() => {
                reject();
            })
    })
}
export function checkSession() {
    return new Promise((resolve, reject) => {
        let uri = APIData.USER + "/auth/session/checkCreate";
        var bodyParamsObj = {}
        fetch(uri, { body: JSON.stringify(bodyParamsObj), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(result => {
                if (result != null) {
                    resolve(result);
                } else {
                    reject();
                }
            }).catch(e => {
                reject();
                console.log("Error while checkSession...: ", e);
            })
    })
}
export function getSupportMessages(scid, offset) {
    return new Promise((resolve, reject) => {
        let uri = APIData.REALTIME + "/support/getMessages?bigDisplay=1&isInverted=1&scid=" + scid + "&offsetMultiplier=" + offset;
        fetch(uri, { credentials: "include" })
            .then(response => {
                console.log("ResponseStatus: ", response.status);
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.log("ErrStatus while loading supportMessages: ", response.status);
                }
            }).then(result => {
                if (result != null) {
                    resolve(result);
                } else {
                    reject();
                }
            }).catch(e => {
                console.log("Error while getSupportMessages: ", e);
                reject();
            })
    })
}
export function getDocumentFileList(shopId, year){
    return new Promise((resolve, reject) => {
        fetch(APIData.MEDIA+`/invoice/list/${shopId}/year/${year}`, {"credentials": "include"})
        .then(response=> {
            if(response.ok){
                return response.json();
            }else{
                console.log("BadStatus while getDocumentFileList: ",response.status);
                return null;
            }
        }).then(r => {
            console.log("DocumentFileListResult: ",r)
            if(r != null){
                resolve(r);
            }else{
                reject();
            }
        }).catch(e => {
            console.log("Error while getDocumentFileList: ",e);
            reject();
        })
    })
}
/**
 * 
 * @param {*} user 
 * @param {*} pw 
 * @description returns an json object with following content { userId, fullname, role, shopIdList, companyId };
 */
export function login(user, pw) {
    return new Promise((resolve, reject) => {
        let uri = APIData.USER + "/auth/session/checkCreate";
        let body = {
            login_type: LOGIN_TYPES.INTERNAL,
            username: user,
            password: pw
        }
        fetch(uri, { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result != null) {
                    resolve(result);
                } else {
                    reject();
                }
            }).catch(e => {
                console.log("APIHelper - Error while login: ", e);
                reject();
            })
    })
}
export function loadShopData(shopIdList) {
    return new Promise((resolve, reject) => {
        if (shopIdList?.length > 0) {
            fetch(APIData.SHOP + "/shop/getByIdList?id=" + shopIdList.join("&id="), { credentials: "include" })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return null;
                    }
                }).then(result => {
                    if (result != null) {
                        resolve(result);
                    } else {
                        reject();
                    }
                }).catch(e => {
                    console.log("Error while loadShopData: ", e);
                    reject();
                })
        } else {
            resolve([]);
        }
    })
}
export function loadPosConfig(shopId, posId) {
    return new Promise((resolve, reject) => {
        fetch(APIData.USER + `/shop/${shopId}/pos/${posId}/getConfig`, { credentials: "include" })
            .then(response => {
                if (response.ok) {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        console.log("EmptyResponse for loadPosConfig status: ", response.status);
                        return { configFields: "{}" };
                    }
                } else {
                    console.log("BadStatus while loadPosConfig: ", response.status);
                    return null;
                }
            }).then(result => {
                if (result != null && result.configFields != null) {
                    // result.configFields = ;
                    result.configFields = JSON.parse(result.configFields);
                    resolve(result);
                } else {
                    reject();
                }
            }).catch(e => {
                console.log("Error while loadPosConfig: ", e);
                reject();
            })
    })
}
export function updatePosConfig(shopId, id, posTypeId, posConfig) {
    return new Promise((resolve, reject) => {
        let url = APIData.USER + `/shop/${shopId}/posConfig/${id}/posType/${posTypeId}/update`
        fetch(url, { credentials: "include", method: "POST", body: JSON.stringify(posConfig), headers: { 'Content-Type': "application/json" } })
            .then(response => {
                if (response.ok) {
                    resolve();
                } else {
                    console.log("ErrStatus while updatePosConfig: ", response.status);
                    reject();
                }
            }).catch(e => {
                console.log("Error while updatePosConfig: ", e);
                reject();
            })
    })
}
export function deletePosConfig(shopId, id, r2oDefaultPid, r2oAccToken) {
    return new Promise((resolve, reject) => {
        let url = APIData.USER + `/shop/${shopId}/posConfig/${id}`
        console.log("Calling deletePosConfig: ", url);
        let body = {};
        if(r2oDefaultPid != null && r2oAccToken != null){
            body = {accountToken : r2oAccToken ,R2ODefaultPID : r2oDefaultPid}
        }
        fetch(url, { method: 'DELETE', body: JSON.stringify(body), credentials: "include" , headers:{"Content-Type": "application/json"}})
            .then(response => {
                if (response.ok) {
                    resolve()
                } else {
                    console.log("ErrStatus while deletePosConfig: ", response.status);
                    reject();
                }
            })
            .catch(e => {
                console.log("Error while deletePosConfig: ", e);
                reject();
            })
    })
}
export function addPosConfig(shopId, posTypeId, posConfig) {
    return new Promise((resolve, reject) => {
        let url = APIData.USER + `/shop/${shopId}/pos/${posTypeId}/addConfig`
        fetch(url, { method: "POST", credentials: "include", body: JSON.stringify(posConfig), headers: { "Content-Type": "application/json" } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log("ErrStatus while addPosConfig: ", response.status);
                }
            }).then(result => {
                if (result?.id != null) {
                    resolve(result.id);
                } else {
                    reject();
                }
            })
            .catch(e => {
                console.log("Error while addPosConfig: ", e);
                reject();
            })
    })
}

export function getR2OGrantAccessUri(shopId) {
    fetch(APIData.USER + "/ready2order/getGrantAccessTokenUri?shopId=" + shopId, { credentials: "include" })
        .then(res => {
            if (res.ok) {
                return res.text();
            } else {
                return null;
            }
        }).then(result => {
            if (result != null) {
                window.location.href = result;
            } else {
                // push something for error.
            }
        })
        .catch(e => { console.log("Error while getR2OGrantAccessUri: ", e) });
}
const R2O_BASEURI = "https://api.ready2order.com";
const R20_APIVERSION = "/v1"
const R2O_GETUSERURI = R20_APIVERSION + "/users"
const R2O_GETPMURI = R20_APIVERSION + "/paymentMethods"
export function getR2OUsers(accToken) {
    return new Promise((resolve, reject) => {
        fetch(R2O_BASEURI + R2O_GETUSERURI, { headers: { "Authorization": "Bearer " + accToken } })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    console.log("BadStatus while getR2OUsers: ", res.status)
                    return null;
                }
                // if(res.data?.length > 0){
                //     resolve(res.data);
                // }else{
                //     resolve()
                // }
            }).then(result => {
                if (result != null) {
                    resolve(result);
                } else {
                    reject();
                }
            })
            .catch(e => {
                console.log("Ready2Order Error while getUsers: ", e);
                reject();
            })
    })
}
export function addUpdateR2OConfig(shopId, configFields, isUpdate) {
    return new Promise((resolve, reject) => {

        fetch(APIData.USER + `/ready2order/shop/${shopId}/addUpdateConfig${isUpdate ? "?isUpdate=true" : ""}`, { credentials: "include", body: JSON.stringify(configFields), method: 'POST', headers: { "Content-Type": "application/json" } })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    console.log("BadStatus while addUpdateR2OConfig: ", res.status);
                    return null;
                }
            }).then(result => {
                if (result != null) {
                    resolve(result.id);
                } else {
                    reject();
                }
            }).catch(e => {
                console.log("Error while addUpdateR2OConfig: ",e);
                reject();
            })
    })
}
export function createR2OUser() {

}
// export function getR2OPaymentMethodAndTypes() {
export function getR2OPaymentMethod(accToken) {
    return new Promise((resolve, reject) => {
        fetch(R2O_BASEURI + R2O_GETPMURI, { headers: { "Authorization": "Bearer " + accToken } })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    console.log("BadStatus while getR2OPaymentMethod: ", res.status)
                    return null;
                }
                // if(res.data?.length > 0){
                //     resolve(res.data);
                // }else{
                //     resolve()
                // }
            }).then(result => {
                if (result != null) {
                    resolve(result);
                } else {
                    reject();
                }
            })
            .catch(e => {
                console.log("Ready2Order Error while getR2OPaymentMethod: ", e);
                reject();
            })
    })
}
export function createR20PaymentMethod() {

}

// }