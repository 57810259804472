import React, { Component } from 'react'
import DefaultFrame from './components/DefaultFrame';
import { Button, TextField, Typography } from '@mui/material';
import SecretTextField from './components/SecretTextField';
import { Delete, Save } from '@mui/icons-material';
import { Box } from '@mui/system';
import ShopSelector from './components/ShopSelector';
import { APIData } from './constants/APIAdresses';
import { ALERT_SEVERITY } from './components/AlertDisplay2';

export class PaypalConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paypalClientId: "",
            paypalApiKey: "",
            paypalApiKeyLoading: false,
            paypalDeleteDisabled: true,
        }
        this.paypalClientId = "";
        this.paypalApiKey = "";
        this.paypalApiKeyId = "";
        this.shopId= "";
    }
    loadPaypalApiKey(shopId) {
        this.shopId= shopId;
        const URI = APIData.USER + "/shop/" + shopId + "/getPaypalAPIKey";
        this.setState({ paypalApiKeyLoading: true });
        fetch(URI, { method: "GET", credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log("1111");
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 204) {
                    this.setState({
                        paypalApiKeyLoading: false
                    });
                } else {
                    throw new Error("Error while loading paypal api key: " + response.status);
                }
            }).then(result => {
                console.log("Paypal API Key: ", result);
                if (result?.apiKey && result?.id) {
                    console.log("Paypal API Key: ", result);
                    console.log("PUTTING IN STATE: ", (result.apiKey != null ? result.apiKey : ""));
                    this.setState({
                        paypalApiKeyLoading: false, paypalApiKey: result.apiKey != null ? result.apiKey : "", paypalClientId: result.clientId != null ? result.clientId : "", paypalDeleteDisabled: false,
                    });
                    this.paypalClientId = result.clientId;
                    this.paypalApiKey = result.apiKey;
                    this.paypalApiKeyId = result.id;
                } else {
                    this.paypalClientId = ""
                    this.paypalApiKey = "";
                    this.paypalApiKeyId = "";
                    this.setState({ paypalApiKeyLoading: false, paypalApiKey: "", paypalClientId: "", paypalDeleteDisabled: true });
                }
            }).catch(e => {
                console.log("Error while loading paypal api key: ", e);
                this.setState({
                    paypalApiKeyLoading: false,
                });
                this.props.showAlert("Fehler beim Laden vom PayPal API-Key.", ALERT_SEVERITY.ERROR)
            })
    }
    addUpdatePaypalKey() {
        console.log("AddUpdatePaypalKey: ", this.state.paypalApiKey);
        this.setState({ paypalApiKeyLoading: true });
        fetch(APIData.USER + `/shop/${this.shopId}/addUpdatePaypalAPIKey`, { method: "PUT", credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ apiKey: this.state.paypalApiKey, clientId: this.state.paypalClientId }) })
            .then(response => {
                console.log("AddUpdateResponseStat: ", response.status);
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error while adding/updating paypal api key: " + response.status);
                }
            }).then(result => {
                console.log("AddUpdateResult: ", result);
                if (result?.id) {
                    this.setState({
                        paypalApiKeyLoading: false,
                        paypalDeleteDisabled: false,
                    });

                    this.props.showAlertDisplay("Paypal API Key wurde erfolgreich gespeichert.", ALERT_SEVERITY.SUCCESS);
                    this.paypalApiKey = this.state.paypalApiKey;
                    this.paypalClientId = this.state.paypalClientId;
                    this.paypalApiKeyId = result.id;
                }
            }).catch(e => {
                console.log("Error while adding/updating paypal api key: ", e);
                this.setState({
                    paypalApiKeyLoading: false,
                })
                this.props.showAlertDisplay("Fehler beim Aktualisieren der PayPal Zugangsdaten.", ALERT_SEVERITY.ERROR);
            })
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                <ShopSelector onChange={(shopId) => { this.loadPaypalApiKey(shopId) }} />
                <Typography variant="h6">PayPal Konfiguration</Typography>
                <Typography variant="body1">
                    Erstellen Sie eine <strong>Client-ID</strong> und ein <strong>Secret</strong> in Ihrem PayPal-Konto und tragen Sie diese hier ein.
                </Typography>
                <Typography style={{ fontWeight: "bold" }}>
                    Hier sind die Schritte zur PayPal-Konfiguration:
                </Typography>
                <ol>
                    <li>Loggen Sie sich in Ihr PayPal-Konto ein.</li>
                    <li>Gehen Sie zu den <strong>Entwicklereinstellungen</strong>.</li>
                    <li>Erstellen Sie eine neue <strong>App</strong>.</li>
                    <li>Tragen Sie die <strong>Client-ID</strong> und das <strong>Secret</strong> in die Felder unten ein.</li>
                    <ol type="a">
                        <li>Client-ID in das erste Feld eingeben.</li>
                        <li>Secret in das zweite Feld eingeben.</li>
                    </ol>
                    <li>Klicken Sie auf <strong>Speichern</strong>, um die Einstellungen zu übernehmen.</li>
                </ol>

                <TextField label="Client-ID" style={{ width: "100%" }} value={this.state.paypalClientId} onChange={(evt) => { this.setState({ paypalClientId: evt.target.value }) }} />
                <SecretTextField label='API-Key' value={this.state.paypalApiKey} onChange={(value) => { this.setState({ paypalApiKey: value }) }} />
                <div style={{ display: "flex", gap: 15, }}>
                    <Button onClick={() => {this.addUpdatePaypalKey()}} disabled={this.state.paypalApiKey === this.paypalApiKey && this.state.paypalClientId === this.paypalClientId} variant='contained' style={{ flex: 1 }} startIcon={<Save />}>{this.state.paypalApiKey.length == 0 && this.state.paypalClientId.length == 0 && this.paypalApiKey.length == 0 ? "Speichern" : "Aktualisieren"}</Button>
                    {/* <Button color="error" variant='contained' style={{ flex: 1 }} startIcon={<Delete />}>Löschen</Button> */}
                </div>
            </div>
        )
    }
}

class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Paypal-Konfiguration">
                <PaypalConfiguration {...this.props} />
            </DefaultFrame>
        )
    }
}
export default defaultWrapper;