import { configureStore } from "@reduxjs/toolkit";
import permissionSlice from "./permissionSlice";
// import supportSlice from "./supportSlice";
import generalSlice from "./generalSlice";
import userManagementSlice from "./userManagementSlice";
import productSlice from "./productSlice";
import appSlice from "./appSlice";

export default configureStore({
    reducer: {
        permission: permissionSlice,
        product: productSlice,
        // support: supportSlice,
        general: generalSlice,
        app: appSlice,
        userManagement: userManagementSlice,
    }
})