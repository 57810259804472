import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import { Delete, Save, CloudDownload } from '@mui/icons-material';
import ShopSelector from '../components/ShopSelector';
import { POS_TYPES, addPosConfig, deletePosConfig, loadPosConfig, updatePosConfig } from '../APIHelper';
import { setPosConfig } from '../redux/appSlice';
import DefaultFrame from '../components/DefaultFrame';
import { setRole } from '../redux/permissionSlice';
import { ALERT_SEVERITY } from '../components/AlertDisplay2';
import { MUI_COLORS } from '../constants/MUIConstants';
const shopList = [
    { id: 1, name: "Viveel Pizza" },
    { id: 2, name: "Testshop" },
]
export class WinOrderConfigPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            posConfig: {},
            currentShopId: null,
        }
        this.initialPosconfig = {};
    }


    render() {
        return (
            <div style={{ width: "100%", height: "100%", padding: "20px" }}>
                <h2>Viveel OrderSync - Automatische Bestellannahme und Druck</h2>
                <p>Viveel OrderSync ist ein von uns entwickeltes Tool, das es Ihnen ermöglicht, Bestellungen automatisch zu empfangen und zu drucken. Dieses Tool ist speziell darauf ausgerichtet, den Bestellprozess zu optimieren und die Effizienz Ihrer Abläufe zu steigern.</p>
                <p>Für die Installation und Einrichtung von Viveel OrderSync, laden Sie bitte die folgenden Dateien herunter und befolgen Sie die detaillierten Anweisungen in der beigefügten Anleitung:</p>
                <div style={{display:"flex", flexDirection:"column",  gap:10}}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudDownload />}
                        onClick={() => window.open('https://drive.google.com/file/d/13ow_VUUL876sF6-w7f0Xkg60QkM6dTuW/view?usp=drive_link', '_blank')}
                        style={{ margin: "10px 0", width:300 }}
                    >
                        Viveel OrderSync
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudDownload />}
                        onClick={() => window.open('https://drive.google.com/file/d/1nSJUcOcNqZ7JRtfldrPTl0Expkl3Ss53/view?usp=drive_link', '_blank')}
                        style={{ margin: "10px 0", width:300 }}
                    >
                        Anleitung
                    </Button>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    console.log("STATE: ", state.app.shopIdList);
    return {
        shopIdList: state.app.shopIdList,
        shopIdData: state.app.shopData,
        currentShopId: state.app.currentShopId,
        posConfig: state.app.posConfig,
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setPosConfig: (posConf) => {
            dispatch(setPosConfig(posConf));
        },
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
// export default connect(mapStateToProps, mapDispatchToProps)(WinOrderConfigPage)


class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Viveel OrderSync - Konfiguration">
                <WinOrderConfigPage {...this.props} />
            </DefaultFrame>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(defaultWrapper);