import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import ShopSelector from '../components/ShopSelector';
import { POS_TYPES, addPosConfig, deletePosConfig, loadPosConfig, updatePosConfig } from '../APIHelper';
import { setPosConfig } from '../redux/appSlice';
import DefaultFrame from '../components/DefaultFrame';
import { setRole } from '../redux/permissionSlice';
import { ALERT_SEVERITY } from '../components/AlertDisplay2';
import { MUI_COLORS } from '../constants/MUIConstants';
const shopList = [
    { id: 1, name: "Viveel Pizza" },
    { id: 2, name: "Testshop" },
]
export class WinOrderConfigPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            posConfig: {},
            currentShopId: null,
        }
        this.initialPosconfig = {};
    }
    save() {
        if (this.state.posConfig.id != null) {
            if(this.state.email === ""){
                this.delete();
                this.setState({email: this.state.posConfig.configFields.email});
                return;
            }
            updatePosConfig(this.state.currentShopId, this.state.posConfig.id, POS_TYPES.WINORDER, { email: this.state.email })
                .then(response => {
                    this.props.showAlertDisplay("Konfiguration aktualisiert.", ALERT_SEVERITY.SUCCESS);
                    this.initialPosconfig = { ...this.initialPosconfig, email: this.state.email };
                    this.setState({ posConfig: { ...this.state.posConfig, configFields: { email: this.state.email } } });
                }).catch(e => {
                    this.props.showAlertDisplay("Fehler beim Speichern der Konfiguration.", ALERT_SEVERITY.ERROR);
                })
        } else {
            addPosConfig(this.state.currentShopId, POS_TYPES.WINORDER, { email: this.state.email })
                .then(response => {
                    this.props.showAlertDisplay("Konfiguration gespeichert.", ALERT_SEVERITY.SUCCESS);
                    this.initialPosconfig = { ...this.initialPosconfig, email: this.state.email };
                    this.setState({ posConfig: { ...this.state.posConfig, configFields: { email: this.state.email } } });
                }).catch(e => {
                    this.props.showAlertDisplay("Fehler beim Speichern der Konfiguration.", ALERT_SEVERITY.ERROR);
                })
        }
        // this.props.showAlertDisplay("Speichern...", ALERT_SEVERITY.INFO);
        // if(this.state.currentShopId != null){

        //     this.props.showAlertDisplay("Kein Shop ausgewählt.", ALERT_SEVERITY.ERROR);
        //     return;
        // }
    }
    delete() {
        this.props.openAlertDialog(
            [
                {
                    label: "Ja",
                    color: MUI_COLORS.ERROR,
                    onChoice: () => {
                        deletePosConfig(this.state.currentShopId, this.state.posConfig.id)
                            .then(result => {
                                // this.props.setPosConfig({});
                                this.initialPosconfig = {}
                                this.setState({ email: "", posConfig: {}, currentShopId: null });
                                this.props.showAlertDisplay("Konfiguration gelöscht.", ALERT_SEVERITY.SUCCESS);
                            }).catch(e => {
                                this.props.showAlertDisplay("Fehler beim Löschen der Konfiguration.", ALERT_SEVERITY.ERROR);
                            })
                    }
                },
                {
                    label: "Nein",
                    onChoice: () => {
                        this.props.showAlertDisplay("Löschen abgebrochen.", ALERT_SEVERITY.INFO);
                    }
                }
            ],
            "Konfiguration löschen", "Möchtest du die Konfiguration wirklich löschen?")

    }
    __loadPosConfig(id) {
        if (id == null) {
            return;
        }
        loadPosConfig(id, POS_TYPES.WINORDER)
            .then(result => {
                console.log("PosConfig: ", result);
                // this.props.setPosConfig(result);
                if (result.configFields != null) {
                    console.log("SETTING STATE: ", result.configFields);
                    this.initialPosconfig = { ...result.configFields };
                    this.setState({ email: result?.configFields?.email, currentShopId: id, posConfig: result });
                }
            }).catch(e => {
                alert("Fehler beim Laden der Anbindung.");
                console.log("WinOrder - Fehler beim Laden der Anbindung: ", e);
            })
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <ShopSelector onChange={(shopId) => { console.log("Changed Shopid: ", shopId); this.__loadPosConfig(shopId) }} />
                <h2>Anbindung über Benutzername & Passwort (empfohlen)</h2>
                <p>Es wird empfohlen die Anbindung in der WinOrder Kasse selbst durchzuführen</p>
                <p>Verwenden hierzu den Verbindungsassistenten im Online-Shop Anbindung der WinOrder Kasse und trage als Endpunkt folgenden Link ein.<br />
                    <span style={{ color: '#0078a1', fontWeight: "bold" }}>https://viveel.de:3001/winorder/<span style={{ color: '#f19535' }}>DEINSHOPID</span>/GetNewOrders</span><br />
                    Ersetze dabei DEINSHOPID durch die ID deines Shops, dass angebunden werden soll.<br /><br />
                    Du hast folgende ShopID's zur auswahl:<br /><br />
                    <span style={{ color: '#f19535', fontWeight: "bold" }}>{this.props.shopIdList.join(",")}</span><br /><br />
                    Beispiel:<br /><br />
                    <span style={{ color: '#0078a1', fontWeight: "bold" }}>https://viveel.de:3001/winorder/<span style={{ color: '#f19535' }}>{this.state.currentShopId != null ? this.state.currentShopId : "0"}</span>/GetNewOrders</span><br />
                </p>
                <p>Als Benutzername und Passwort, kannst du deine Zugangsdaten für den ShopManager bzw. den Partner Hub verwenden.</p>
                <br /><br />
                <h2>Anbindung über E-Mail Adresse:</h2>
                <Typography>Zuerst musst du in WinOrder den Online-Shop anlegen und die E-Mail adresse dort Konfigurieren.</Typography>
                <br /><Typography>Anschließend kannst du im folgenden die E-Mail adresse eintragen, um die Übermittlung per E-Mail zu konfigurieren.<br />Anders als bei der Methode mit Benutzername & Passwort bekommt Viveel durch E-Mail Konfiguration keine Rückmeldung von der Kasse über Lieferzeiten oder Status der Bestellung. Daher empfehlen wir die Konfiguration mit Benutzername & Passwort.</Typography>
                <br />
                <TextField value={this.state.email} onChange={(evt) => { this.setState({ email: evt.target.value }) }} label="E-Mail" variant='outlined' style={{ width: "100%" }} />
                <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                    <Button variant='contained' disabled={this.state.email === this.initialPosconfig.email} onClick={() => { this.save() }} style={{ width: "100%", marginTop: 15 }} startIcon={<Save />} >{this.state.posConfig.id != null ? "Aktualisieren" : "Speichern"}</Button>
                    <Button variant='contained' disabled={this.state.posConfig.id == null} color='error' onClick={() => { this.delete() }} style={{ width: "100%", marginTop: 15 }} startIcon={<Delete />} >Löschen</Button>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    console.log("STATE: ", state.app.shopIdList);
    return {
        shopIdList: state.app.shopIdList,
        shopIdData: state.app.shopData,
        currentShopId: state.app.currentShopId,
        posConfig: state.app.posConfig,
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setPosConfig: (posConf) => {
            dispatch(setPosConfig(posConf));
        },
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
// export default connect(mapStateToProps, mapDispatchToProps)(WinOrderConfigPage)


class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Winorder - Konfiguration">
                <WinOrderConfigPage {...this.props} />
            </DefaultFrame>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(defaultWrapper);