import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setRole } from './redux/permissionSlice'
import "./assets/css/general.css";
import TileButton from './components/TileButton';
import DefaultFrame from './components/DefaultFrame';

export class POSIntegration extends Component {
    render() {
        return (
            <DefaultFrame title="Kassenanbindung" noPermissionCheck>
                <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"center", marginTop:"2.5%"}}>
                    <TileButton path="./expertorder" title="ExpertOrder" iconSrc={require("./assets/poslogos/eo-logo.png")} />
                    <TileButton path="./liefersoft" title="Liefersoft" iconSrc={require("./assets/poslogos/liefersoft-logo.png")}/>
                    <TileButton path="./ready2order" title="Ready2Order" iconSrc={require("./assets/poslogos/r2o-logo.png")}/>
                    <TileButton path="./winorder" title="WinOrder" iconSrc={require("./assets/poslogos/wo-logo.png")}/>
                    <TileButton path="./viveelordersync" title="Viveel OrderSync" iconSrc={require("./assets/icons/viveel_logo128x128.png")}/>
                </div>
            </DefaultFrame>
        )
    }
}

export default POSIntegration
