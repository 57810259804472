import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './Login';
import store from './redux/store';
import { Provider } from 'react-redux';
import Dashboard from './Dashboard';
import AlertDialog from './components/AlertDialog';
import InvoicePage from './InvoicePage';
import POSIntegration from './POSIntegration';
import Ready2OrderConfigPage from './POSIntegrationPages/Ready2OrderConfigPage'
import LiefersoftConfigPage from './POSIntegrationPages/LiefersoftConfigPage'
import WinOrderConfigPage from './POSIntegrationPages/WinOrderConfigPage'
import ExpertOrderConfigPage from './POSIntegrationPages/ExpertOrderConfigPage'
import PaypalConfiguration from './PaypalConfiguration';
import ViveelOrderSyncPage from './POSIntegrationPages/ViveelOrderSyncPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
export const ROUTES = {
    DASHBOARD: "/dashboard",
    SUPPORT: "/support",
    USERS: "/users",
    ORDERS: "/orders",
    PARTNERS: "/partners",
    PARTNER: (companyId) => companyId != null ? `/partner/${companyId}` : "/partner/:companyId",
    SHOP: (companyId, shopId) => companyId != null && shopId != null ? `/partner/${companyId}/shop/${shopId}` : "/partner/:companyId/shop/:shopId",
}
const router = createBrowserRouter([
    { path: "/", element: <Login />, errorElement: <p>Error 404 not found...</p> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/invoice", element: <InvoicePage /> },
    { path: "/paypalconfig", element: <PaypalConfiguration /> },
    { path: "/posconfig", element: <POSIntegration /> },
    { path: "/posconfig/ready2order", element: <Ready2OrderConfigPage /> },
    { path: "/posconfig/liefersoft", element: <LiefersoftConfigPage /> },
    { path: "/posconfig/winorder", element: <WinOrderConfigPage /> }, 
    { path: "/posconfig/expertorder", element: <ExpertOrderConfigPage />},
    { path: "/posconfig/viveelordersync", element: <ViveelOrderSyncPage />}
]);
root.render(
    <Provider store={store}>
        <RouterProvider router={router} />
        <AlertDialog />
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
