import { ArrowBack, ArrowForward, ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Button, Card, IconButton, Typography } from '@mui/material';
import React, { Component } from 'react'

export class EntitySelection extends Component {
    // apiEndPoint
    // paginationKey
    // entity title key
    // entity id key
    // localPagination : Means that we have the whole data and have to page it locally
    constructor(props) {
        super(props);
        this.state = {
            selectedId: props.selectedId ? props.selectedId : -1,
            currentPage: 0,
            data: [],
            tableData: [],
        }
        this.entityTitleKey = this.props.entityTitleKey ? this.props.entityTitleKey : "name";
        this.entityAdditionalTitleKey = this.props.entityAdditionalTitleKey ? this.props.entityAdditionalTitleKey : null;
        this.entityIdKey = this.props.entityIdKey ? this.props.entityIdKey : "id";
        this.pageSize = props.pageSize ? props.pageSize : 10;
    }
    componentDidUpdate(prevProps) {
        if (prevProps.apiEndPoint != this.props.apiEndPoint) {
            // this.setState({ selectedId: this.props.selectedId });
            this.setState({
                selectedId: this.props.selectedId ? this.props.selectedId : -1,
                currentPage: 0,
                data: [],
                tableData: [],
            })
            this.loadData(this.props.paginationKey != null ? 0 : null);
        }
    }
    loadData(page) {
        console.log("#######LOADDATA: ", this.props.apiEndPoint);
        if (this.props.apiEndPoint) {
            let url = this.props.apiEndPoint + (this.props.paginationKey != null && page != null ? (this.props.apiEndPoint.includes("?") ? "&" : "?") + this.props.paginationKey + "=" + page : "");

            console.log("CALLING API: ", url);
            fetch(url,
                { credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if(response.ok){
                        response.json()
                    }else {
                        console.log("ErrStatus while loading data: ", response.status);
                    }
                }).then(data => {
                    if (data != null) {
                        console.log("ENTITYSELECTIONData: ", data);
                        this.setState({
                            data: data,
                            tableData: data.length > this.pageSize ? data.slice(0, this.pageSize) : data,
                            currentPage: page != null ? page : this.state.currentPage
                        }, () => {
                            console.log("TABLEDATA IN STATE: ", this.state.tableData);
                        })
                    }
                }).catch(e => {
                    console.log("Error while loading data: ",e)
                })
        }
    }
    componentDidMount() {
        console.log("ENTITYSELECTION: ", this.props);
        this.loadData(this.props.paginationKey != null ? 0 : null);
    }
    changePage(direction) {
        if (this.props.localPagination) {
            console.log("(this.state.currentPage + direction) * this.pageSize + this.pageSize: ", (this.state.currentPage + direction) * this.pageSize + this.pageSize);
            if (
                (direction < 0 && this.state.currentPage > 0) ||
                this.state.data.length > 0 && this.state.data.length > (this.state.currentPage + direction) * this.pageSize) {
                console.log("ARE WE HERE??")
                this.setState({
                    tableData: this.state.data.slice((this.state.currentPage + direction) * this.pageSize, (this.state.currentPage + direction) * this.pageSize + this.pageSize),
                    currentPage: this.state.currentPage + direction
                })
            }
        } else {
            this.loadData(this.state.currentPage + direction);
        }
    }
    render() {
        return (
            <Card style={{ padding: 15, border: '0.5px solid #dedede', borderRadius: 4, display: "flex", flexDirection: "column", gap: 15 }}>
                <Typography style={{ color: '#676767', fontStyle: 'italic' }}>{this.props.title ? this.props.title : "Entität Auswählen..."}</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                    {
                        this.state.tableData.map((entity, index) => {
                            return (
                                <div onClick={() => {
                                    this.setState({ selectedId: entity[this.entityIdKey] });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(entity[this.entityIdKey], entity[this.entityTitleKey]);
                                    }
                                }}
                                    style={{ userSelect: 'none', cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1, flexBasis: 50, padding: 10, borderRadius: 5, border: this.state.selectedId == entity[this.entityIdKey] ? "1px solid #91cfff" : "0.5px solid #dedede" }}>
                                    <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{entity[this.props.entityTitleKey]}{this.props.entityAdditionalTitleKey != null && <Typography style={{color:'#676767'}}>{entity[this.props.entityAdditionalTitleKey]}</Typography>}</Typography>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ display: "flex", gap: 10, justifyContent: "center", flexDirection: "row" }}>
                    <Button color='primary' variant='outlined' onClick={() => { this.changePage(-1) }} disabled={this.state.currentPage == 0}>
                        <ArrowBack />
                    </Button>
                    <Button color='primary' variant='outlined' onClick={() => { this.changePage(1) }}>
                        <ArrowForward />
                    </Button>
                    {/* <Button disabled={this.state.currentPage==0}></Button> */}
                    {/* <Button >Vorher</Button> */}
                </div>
            </Card>
        )
    }
}

export default EntitySelection