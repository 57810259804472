export const APIData = {
    "SHOP": "https://shop.viveel.de",
    "USER": "https://user.viveel.de",
    "REALTIME": "https://realtime.viveel.de",
    "MEDIA": "https://media.viveel.de",
    "MEDIA_TYPES" : 
        {
            PRODUCTS: "products", 
            CATEGORIES: "categories",
            BACKGROUNDS: "backgrounds", 
            HEADERS: "headers", 
            ICONS: "icons"
        }
}