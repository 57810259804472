import React, { Component } from 'react'
import "./assets/css/general.css";
import Header from './components/Header';
import { Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import TileButton from './components/TileButton';
import { setRole } from './redux/permissionSlice';
import { PermissionAreaComponent } from './hookInjection/PermissionAreaComponent';

import DefaultFrame from './components/DefaultFrame';



class Dashboard extends Component {
    constructor(props) {
        super(props);
    }
    withPermission
    render() {
        return (
            
            <DefaultFrame title="Dashboard" noPermissionCheck>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "2.5%" }}>
                    <TileButton path="/posconfig" title="Kassenanbindung" iconSrc={require("./assets/icons/pos-terminal.png")} />
                    <TileButton path="/invoice" title="Dokumente" iconSrc={require("./assets/icons/invoice.png")} />
                    <TileButton path="/paypalconfig" title="PayPal-Anbindung" iconSrc={require("./assets/icons/paypal_icon.png")} />

                </div>
            </DefaultFrame>
        )
    }
}
function mapStateToProps(state) {
    return {
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
